<template>
    <div>
        <b-card class="top">
            <div class="header">
                <h4>Logs Table</h4>
            </div>
            <div class="area scrollable">
            <BMSTable
                :items="items"
                :fields="fields"
                :sort-by="sortBy"
                :urlBuilder="getRowClicked"
                />
            </div>
        </b-card>
        <b-card class="bottom">
            <div class="header d-flex justify-content-between">
                <div class="d-flex align-center">
                    <h4>Logs Stream</h4>
                    <b-button size="sm" variant="primary" class="mb-2">
                        <b-icon icon="arrow-down-circle-fill" aria-label="down" @click="scrollSmoothTo('bottom')"></b-icon>
                    </b-button>
                    <b-button size="sm" variant="primary" class="mb-2">
                        <b-icon icon="arrow-up-circle-fill" aria-label="up" @click="scrollSmoothTo('top')"></b-icon>
                    </b-button>
                </div>
                <div>
                    <b-button variant="primary" @click="getTodayLogs">Today</b-button>
                </div>
            </div>
            <div class="scrollable area bg-dark p-2" >
                <pre id="top" class="text-white">{{ this.logs }}</pre>
                <span id="bottom"></span>
            </div>
        </b-card>
    </div>
</template>

<script>

import { getLogsList, getTodayLogs, getDatedLogs} from '@/api/logs.api'
import crudMixin from '@/api/crud'
import BMSTable from '@/components/BMSTable';

export default{
    components: {
        BMSTable
    },
    mixins: [crudMixin],
    data(){
        return{
            logs: '',
            items: null,
            sortBy: 'last_modified',
            fields: [{
                key: 'Key',
                label: 'File name',
                sortable: true,
            },
            {
                key: 'Size',
                label: 'Size',
            },
            {
                key: 'LastModified',
                label: 'Last modified',
                sortable: true,
            }]
        }
    },
    mounted() {
        this.getLogsList();
        this.getTodayLogs();
    },
    methods:{
        getLogsList: function(){
            
            this.call(
                getLogsList()
                ,(res)=>{
                    this.items = res.data.Contents
                    this.items.shift()
                }
            )
        },
        getTodayLogs: function(){
            this.logs = []
            this.call(
                getTodayLogs()
                ,(res)=>{
                    this.logs = res.data
                }
            )
        },
        getRowClicked: function(item){
            this.call(
                getDatedLogs(item.Key.slice(5))
                ,(res)=>{
                    this.logs = ''
                    this.logs = res.data
                }
            )
        },
        scrollSmoothTo: function(elementId) {
            var element = document.getElementById(elementId);
                element.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
                });
        },
    },
}
</script>
<style scoped>
.scrollable {
   overflow-y: scroll;
}
.area{
    max-height: 260px !important;
}
.top, .bottom{
    min-height: 350px !important;
    max-height: 340px !important;
}
</style>
import httpClient from './httpClient';

const END_POINT = '/logs';

const getLogsList = () => httpClient.get(END_POINT);
const getTodayLogs = () => httpClient.get(END_POINT+ "/today")
const getDatedLogs = (filename) => httpClient.get(END_POINT+ "/"+ filename)
export{
    getLogsList,
    getTodayLogs,
    getDatedLogs
}